enum API {
  ADMIN = "/admin",
  COMMON_DATA = "/common_data",

  UPLOAD_FILE = "/files/upload",
  UPLOAD_TEST_ITEM_FILE = "/files/upload/test-item",
  DOWNLOAD_FILE = "/files/download",

  USER = "/users",

  LOGIN = "/users/login",
  SKU = "/skus",
  SKU_GROUPING = "/sku_grouping",
  TEST_MATRIX = "/test-matrix",
  TEST_ITEM = "/test-item",
  ITEMS = "/items",
  BRAND = "/brand",
  SUB_CATEGORY = "/sub-category",
  CHANGE_REQUEST = "/change-request",

  SUPPLIERS = "/suppliers",
  SUPPLIER = "/supplier",
  SUPPLIERS_PROFILE = "/supplier/profile",
  OPEN_TASK = "/open_task",
  SUPPLIERS_FILTER_OPTIONS = "/suppliers/filter_options",
  LABS = "/labs",
  LAB = "/lab",
  STATUS_SUBMISSIONS = "/status/submissions",

  SUBMISSIONS = "/submissions",
  TRF = "/trf",
  INVOICE = "/invoice",
  STATUS = "/status",

  BUYER = "/buyer",
  BUYER_REPORTS = "/buyer/reports",

  USER_SURVEYS = "/surveys"
}

export default API;
