import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import { useEffect, useMemo, useState } from "react";

import { useErrorAlert, useSuccessAlert, useUserRole, useUserRoleUrl } from "hooks";

import {
  ItemDetailSummary,
  ItemFactory,
  ItemSupplierFactory,
  ItemTask,
  SkuItem,
  SkuListItem,
} from "types/item";

import API from "constants/api";
import { BUYER, SUPPLIER } from "constants/global";
import { History } from "types";
import { FetchOption } from "types/global";
import { HistoryLog } from "../components/Log";

export interface SkuDeptOption {
  id: number;
  deptNum: string;
  deptDesc: string;
}

export interface SkuVendorOption extends Pick<FetchOption, "id"> {
  vendorNum: string;
  vendorDesc: string;
}

/**
 * hepler functions
 */
function useFetchItem<T>({
  path,
  method = "GET",
  api = API.SKU,
  id = "",
  url = "",
}: {
  path?: string;
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
}) {
  const { setError } = useErrorAlert();

  const { isBuyer, isSupplier } = useUserRole();

  const [data, setData] = useState<T>();

  const PATH = path || useUserRoleUrl(api);

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: data as T,
      isLoading,
      refetch,
      error,
    }),
    [id, url, data, isLoading],
  );
}

function useUpdateItem<Req, Res = { id?: string }>({
  method = "POST",
  api = API.SKU,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
  ...rest
}: {
  // method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
} & AxiosRequestConfig) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const { isBuyer } = useUserRole();

  const [data, setData] = useState<Res>();

  const PATH = isBuyer ? `/${BUYER}${api}` : `/${SUPPLIER}${api}`;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
      ...rest,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

function useDeleteItem<Req, Res = { id?: string }>({
  method = "DELETE",
  api = API.SKU,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const { isBuyer } = useUserRole();

  const [data, setData] = useState<Res>();

  const PATH = isBuyer ? `/${BUYER}${api}` : `/${SUPPLIER}${api}`;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (selectedId: number) =>
        refetch({ url: !id && !url ? PATH : `${PATH}/${id}${url}/${selectedId}` }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

export function useCreateSku<T = SkuItem>() {
  return useUpdateItem<T>({
    method: "POST",
    successAlertEnabled: true,
    successMessage: "SKU has been created successfully.",
  });
}

export function useRemoveSkuFile<T>(skuId: string) {
  return useDeleteItem<T>({
    id: skuId,
    url: "/file",
    successAlertEnabled: true,
    successMessage: "File removed successfully.",
  });
}

export function useEditSkuDetail<T = SkuListItem>(skuId: string) {
  return useUpdateItem<T>({
    method: "PUT",
    id: skuId,
    successAlertEnabled: true,
    successMessage: "Info updated successfully.",
  });
}

export function useEditItemFactory<T = Partial<ItemFactory>>(skuId: string) {
  return useUpdateItem<T>({
    method: "PUT",
    id: skuId,
    url: "/factory",
    successAlertEnabled: true,
    successMessage: "Info updated successfully.",
  });
}

export function useSkuDetail<T = SkuListItem>(skuId: string) {
  return useFetchItem<T>({ id: skuId });
}

export function useItemTasks<T = ItemTask[]>(skuId: string) {
  return useFetchItem<T>({ id: skuId, url: "/tasks" });
}

export function useItemTaskDetail<T = ItemDetailSummary>(skuId: string, taskRequirementId: string) {
  return useFetchItem<T>({ id: skuId, url: `/task_requirements/${taskRequirementId}` });
}

export function useSkuHistory<T = HistoryLog[]>(skuId: string) {
  return useFetchItem<T>({ id: skuId, url: "/history" });
}

export function useBuyerDescs<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/buyer_descs",
  });
}

export function useSkuSubDepts<T = SkuDeptOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/sub_depts",
  });
}

export function useSkuCollections<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/collections",
  });
}

export function useSkuFrames<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/frames",
  });
}

export function useSkuColorCodes<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/color_codes",
  });
}

export function useSkuSizeCodes<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/size_codes",
  });
}

export function useSkuFabrics<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/fabrics",
  });
}

export function useSkuFills<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/fills",
  });
}

export function useSkuFinishes<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/finishs",
  });
}

export function useSkuFunctions<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/functions",
  });
}

export function useSkuCountryOfOrigins<T = FetchOption[]>() {
  return useFetchItem<T>({
    path: `${BUYER}${API.SKU}`,
    url: "options/country_of_origins",
  });
}

export function useSkuDepts<T = SkuDeptOption[]>() {
  return useFetchItem<T>({
    path: `${useUserRoleUrl(API.SKU)}`,
    url: "options/depts",
  });
}

export function useSkuVendors<T = SkuVendorOption[]>() {
  return useFetchItem<T>({
    path: `${useUserRoleUrl(API.SKU)}`,
    url: "options/vendors",
  });
}

// item detail
export function useItemSupplierAndFactory<T = ItemSupplierFactory>(masterWicId: string) {
  return useFetchItem<T>({
    api: API.ITEMS,
    url: `master_wic/${masterWicId}/suppliers_and_factory`,
  });
}

export function useExportAllSku<T>() {
  return useUpdateItem<T>({
    method: "POST",
    url: "all_skus/download",
    successAlertEnabled: true,
    successMessage: "SKU exported successfully.",
    // headers: { "Content-Type": "application/octet-stream" },
    responseType: "blob",
  });
}
