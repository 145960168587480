import { ActiveStatus, Currency } from "types/global";

import { TaskStatus } from "types/task";

export enum FactoryStatus {
  All = "all",
  Active = "active",
  ActiveWithinTwoMonths = "active_within_two_months",
  Inactive = "inactive",
}

export enum FacilityType {
  Factory = "FACTORY",
  Warehouse = "WAREHOUSE",
}

export enum Affiliation {
  Contracted = "contracted",
  Joint_venture = "joint_venture",
  Owned = "owned",
  Single_ownership = "single_ownership",
  Publicly_traded = "publicly_traded",
}
export interface FactoryListItem {
  id: number;
  name: string | null;
  factoryNo: string | null;
  status: ActiveStatus | null;
  // factoryLastValidityDate: string | null; // tooltip, Last Validity Date: 12/Mar/2023

  country: string | null;
  state: string | null;

  asiaCategories: [] | null;
  exportMarkets: [] | null;
  validityDate: string | null;
}
export interface FilterFactoryListItem {
  asiaCategory: any[];
  country: any[];
  exportMarket: any[];
  name: any[];
  state: any[];
}

export interface City {
  id: number;
  countryId: number;
  countryName: string;
  name: string;
  stateId: number;
  stateName: string;
}

export interface OpenTaskDetailFactory {
  id: number;
  contactEmail: string;
  contactNumber: string;
  contactPerson: string;
  createdTime: string;
  factoryAddress1: string;
  factoryAddress2: string;
  factoryCode: string;
  factoryId: number;
  factoryName: string;
  isActive: boolean;
  city: City;
}

export interface OpenTaskDetailBilling {
  id: number;
  address: string;
  bankAddress: string;
  bankName: string;
  city: City;
  contactNumber: string;
  currency: Currency;
  enterpriseName: string;
  invoiceType: string;
  payerCompanyAddress: string;
  payerCompanyName: string;
  payerContactPerson: string;
  payerEmail: string;
  payerPhoneNumber: string;
  taxInformationFiles: string;
  taxRegistrationCode: string;
}

export interface FactorySupplierItem {
  id: number;
  name: number;
  status: string | null;
  factoryStatus: string | null;
  supplierNo: string | null;

  state: string | null;
  country: string | null;
  relationshipStatus: string | null;

  asiaCategories: [] | null;
  regions: [] | null;
  exportMarkets: [] | null;
  brands: [] | null;
}

export interface FactoryFilterListItem {
  supplier: any[];
  relationshipStatus: any[];
  state: any[];
  country: any[];
  asiaCategory: any[];
  exportMarket: any[];
}
export interface FactoryLogItem {
  action: string | null;
  afterChange: string | null;
  beforeChange: string | null;
  actor: string | null;
  date: string | null;
}
export interface FactoryBasicInfo {
  name: string | null;
  factoryNo: string | null;
  state: string | null;
  country: string | null;
  responsiblePerson: string | null;
  responsiblePersonEmail: string | null;
  responsiblePersonPhoneNumber: string | null;
}

// factory comparision & factory list
export interface Factory {
  id: number;
  factoryNo: string | null;
  additionalFactoryNo?: string[];
  asiaCategories: string[];
  exportMarkets: string[];

  name: string | null;
  status: FactoryStatus; // status dot
  taskStatus: TaskStatus; // Task Status
  auditWindowPeriod: string | null; // Audit window: 12-20/May/2022
  auditDate: string | null; // Audit Day: N/A
  auditResult: any; // Audit Result: N/A
  auditWindowFrom: string | null;
  auditWindowTo: string | null;
  businessType: string | null;

  validityDate: string | null;
  lab: string | null;
  arrangeBySupplier: string | null;

  address: string | null;
  state: string | null;
  country: string | null;
  postalCode: string | null;
  phoneNumber: string | null;
  responsiblePerson: string | null;
  responsiblePersonPosition: string | null;
  responsiblePersonPhoneNumber: string | null;
  responsiblePersonEmail: string | null;

  scResponsiblePerson: string | null;
  scResponsiblePersonPosition: string | null;
  scResponsiblePersonPhoneNumber: string | null;
  scResponsiblePersonEmail: string | null;

  productManufactured: number | null;
  facilityType: string | null;
  affiliation: Affiliation;
  isWalgreenImporter: boolean;
  primaryPort: string | null;
  yearBuilt: string | null;
  noOfEmployees: string | null;
  noOfProductionEmployees: string | null;
  noOfStories: string | null;
  noOfProductionBuilding: string | null;
  productionArea: string | null;
  noOfWarehouse: string | null;
  warehouseArea: string | null;
  noOfDormitoryBuilding: string | null;
  percentOfDormitoryUtilization: string | null;
  hasUnion: boolean;
  hasAgreed: boolean;
  factoryCapability: string | null;

  isReal: boolean; // check for factory is confirmed
}

export interface SupplierFactoryAction {
  city: { id: number };
  name: string;
  address1: string;
  address2: string;
  contactPerson: string;
  contactEmail: string;
  contactNumber: string;
  factoryCode?: string;
}

export interface SupplierBillingAction {
  city: { id: number };
  currency: string;
  payerCompanyName: string;
  payerContactPerson: string;
  payerEmail: string;
  payerPhoneNumber: string;
  payerCompanyAddress: string;
}
