import { styled } from "@mui/styles";
import React from "react";

import { Box, BoxProps, Grid } from "@mui/material";
import { Text, TextProps } from "components/core";
import { SubHeaderButtonList } from "types";

const ListPanelBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const FlexRowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexFlow: "row",
});

export interface ListPanelProps {
  sx?: BoxProps["sx"];
  boxProps?: BoxProps;
  title?: string;
  titleProps?: Omit<TextProps, "children">;
  startActionList?: SubHeaderButtonList[];
  endActionList?: SubHeaderButtonList[];
}

export default function ListPanel({
  sx,
  boxProps,
  title = "Title",
  titleProps,
  startActionList,
  endActionList,
}: ListPanelProps) {
  return (
    <ListPanelBox {...boxProps} sx={sx}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // sx={{ margin: "0px 20px" }}
      >
        <Grid item>
          <FlexRowBox>
            {title && (
              <Text sx={{ marginRight: "16px" }} {...titleProps} size="title">
                {title}
              </Text>
            )}

            {startActionList?.length
              ? startActionList?.map((item) => {
                  return <React.Fragment key={item.id}>{item.component}</React.Fragment>;
                })
              : null}
          </FlexRowBox>
        </Grid>

        <Grid>
          <FlexRowBox>
            {endActionList?.length ? (
              <Grid container spacing={1}>
                {endActionList?.map((item) => {
                  return (
                    <Grid key={item.id} item>
                      {item.component}
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
          </FlexRowBox>
        </Grid>
      </Grid>
      {/*<FlexRowBox>*/}
      {/*  {title && (*/}
      {/*    <Text sx={{ marginRight: "16px" }} {...titleProps} size="title">*/}
      {/*      {title}*/}
      {/*    </Text>*/}
      {/*  )}*/}

      {/*  {startActionList?.length*/}
      {/*    ? startActionList?.map((item) => {*/}
      {/*        return <React.Fragment key={item.id}>{item.component}</React.Fragment>;*/}
      {/*      })*/}
      {/*    : null}*/}
      {/*</FlexRowBox>*/}

      {/*<FlexRowBox>*/}
      {/*  {endActionList?.length*/}
      {/*    ? endActionList?.map((item) => {*/}
      {/*        return <React.Fragment key={item.id}>{item.component}</React.Fragment>;*/}
      {/*      })*/}
      {/*    : null}*/}
      {/*</FlexRowBox>*/}
    </ListPanelBox>
  );
}
