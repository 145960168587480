import React, { useState } from "react";

export function useStep(defaultStep?: number) {
  const [step, setStep] = useState<number>(defaultStep || 0);

  const goNextStep = () => setStep((state) => state + 1);

  const goPreviousStep = () => setStep((state) => state - 1);

  const resetStep = () => setStep(defaultStep || 0);

  const goToStep = (stepNumber: number) => setStep((state) => stepNumber);

  return {
    step,
    goNextStep,
    goPreviousStep,
    goToStep,
    resetStep,
  };
}
