import useAxios from "axios-hooks";
import { useEffect, useMemo } from "react";
import API from "constants/api";
import {
  ADMIN,
  BUYER_DEPT_KEY,
  BUYER_ROLE_KEY,
  STORAGE_EMAIL_KEY,
  STORAGE_ROLE_KEY,
  STORAGE_TOKEN_KEY,
} from "constants/global";
import { User } from "types/user";
import { useErrorAlert } from "./useAlert";
import { useUserInfo } from "./useAuth";

export function useAdminUserList<TData = User[]>(userListType: "buyers" | "suppliers" | "labs") {
  const { setError } = useErrorAlert();

  const baseUrl = `${API.ADMIN}/list/${userListType}`;

  const [{ data: responseData, loading: isLoading, error }, refetch] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      data: responseData?.data as TData,
      isLoading,
      refetch: ({ currentPage, rowsPerPage }: { currentPage: number; rowsPerPage: number }) => {
        return refetch({
          url: baseUrl,
          params: {
            currentPage,
            rowsPerPage,
          },
        });
      },
    }),
    [isLoading, userListType],
  );
}

export function useHackAccount() {
  const { setError, setAppError } = useErrorAlert();

  const { isLoading: isUserInfoLoading, refetch: fetchUserInfo } = useUserInfo();

  const [{ data: responseData, loading: isLoading, error }, refetch] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (!responseData?.data) return;

    fetchUserInfo();
  }, [responseData?.data]);

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  const handleRefetch = async (userId: number) => {
    refetch({
      url: `/${ADMIN}/user_account/${userId}`,
    }).then(async (res) => {
      if (!res?.data?.data) {
        setAppError({
          code: res?.data?.code,
          message: res?.data?.message,
        });

        return;
      }

      const { role, token } = res.data.data;

      const bearerToken = `Bearer ${token}`;

      localStorage.setItem(STORAGE_ROLE_KEY, role);
      localStorage.setItem(STORAGE_TOKEN_KEY, bearerToken);

      const hackLink = `${window.location.origin}/hack-account/${token}`;

      window.open(hackLink);

      await fetchUserInfo()?.then((userInfoRes) => {
        if (!userInfoRes?.data?.data) {
          setAppError({
            code: userInfoRes?.data?.code,
            message: userInfoRes?.data?.message,
          });

          return;
        }

        const userEmail = userInfoRes?.data?.data?.email as string;
        const buyerRole = userInfoRes?.data?.data?.buyerInfo?.buyerRole as string;
        const buyerDeptNum = userInfoRes?.data?.data?.buyerInfo?.deptNum as string;

        localStorage.setItem(STORAGE_EMAIL_KEY, userEmail);
        localStorage.setItem(BUYER_DEPT_KEY, buyerDeptNum);
        localStorage.setItem(BUYER_ROLE_KEY, buyerRole);
      });
    });
  };

  return useMemo(
    () => ({
      data: responseData?.data,
      isLoading: isLoading || isUserInfoLoading,
      refetch: handleRefetch,
      error,
    }),
    [isLoading],
  );
}
